body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  place-items: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: black;
}

.App {
  width: 100%;
  flex-direction: column;
  top: 50%;
  left: 50%;
  max-width: 100vw;
  text-align: center;
}

.header {
  font-family: "Poppins", sans-serif;
  margin-top: 25vh;
  font-size: 6vw;
  color: white;
}

.subheading {
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 3vw;
}

.progress-container {
  width: 80%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.progress-bar {
  width: 50vw;
  background-color: #f3f3f3;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.progress-bar-fill {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3vh;
  background-color: #4caf50;
  border-radius: 6px;
  color: white;
  text-align: center;
  white-space: nowrap;
  position: relative;
}

.progress-bar-text {
  position: absolute;
  width: 100%;
  text-align: center;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}
